header {
  order: 0;
  margin-right: 0px;
}

footer {
  order: 2;
  padding: 16px 10px;
}

.nav-link :hover {
  cursor: pointer !important;
}

.nav-tabs .nav-link {
  color: #aaa;
  &.active {
    color: #444;
    background-color: #d6f5dd88;
  }
}

.form-switch .form-check-input {
  border-color: #888;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23666'/%3e%3c/svg%3e");
  border-width: 2px;
}

.toast-body {
  color: #020;
  background-color: white;
}

/* h4は各ページのタイトル部分に使用 */
h4 {
  font-size: 1.2rem;
}

table {
  font-size: 14px;
}

thead {
  background-color: hsl(200, 60%, 85%);
}

.notes {
  font-size: smaller;
}

.sticky-top {
  top: 50px !important;
}

.selectedRow {
  background-color: #d6f5dd88;
}

.deleted-tab {
  color: red;
}

.row-total {
  background-color: #fffad2;
}

.summary-table {
  font-size: 0.8rem;
  line-height: 1;
}

.chart-border {
  box-shadow: 0px 0px 2px #aaa;
  padding: 1rem 1rem 1rem 0rem;
}

.agg-table-border {
  box-shadow: 0px 0px 2px #aaa;
  padding: 1rem 1rem 1rem 1rem;
}

.accordion-button {
  &.collapsed {
    background-color: #f3f7fb;
  }
}

.modal-header {
  padding: 0.7rem 1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  .modal-title {
    color: #444;
  }
}
